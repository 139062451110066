import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowDownIcon } from '@heroicons/react/solid';
import PhotoGallery from '../../components/PhotoGallery';
import ContactUs from '../../components/ContactUs';
import SEO from '../../components/SEO';

export default function SummerCampPage() {
  const data = useStaticQuery(graphql`
  query {
    splash: file(relativePath: { eq: "summer-camp.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, )
      }
    }
    fighting: file(relativePath: { eq: "fighting-stance.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: NORTH }, )
      }
    }
    gallery: sanityGallery(name: {eq: "Summer Camp"}) {
      photos {
        id
        image {  
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        name
        order
        tags {
          title
        }
        caption
      }
      name
      caption
    }
  }
`);
  return (
    <>
      <SEO
        title="Summer Camps"
        description="Yuan Yen Do hosts Summer camps &amp; Clinics for it&rsquo;s members annually. Our Summer Camp includes one week of specialized karate training
        and is for students who are between the ages of 6 and 14."
        image={data.splash}
      />
      <section className="relative z-20 xl:container xl:mx-auto">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Dedicate yourself </span>
              {' '}
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-red-600 xl:inline">to self improvement</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Yuan Yen Do hosts Summer camps &amp; Clinics for it&rsquo;s
              members annually. Our Summer Camp includes one week of specialized karate training
              and is for students who are between the ages of 6 and 14.

              For more information on dates, times and details please contact us
            </p>
            <div className="mt-10 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <AnchorLink
                  to="/events/summer-camps#contact-us"
                  title="Tournament Info"
                  className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
                >
                  <ArrowDownIcon className="w-5 h-5 pr-2" />
                  Contact Us
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <GatsbyImage
            className="absolute inset-0 w-full h-full filter drop-shadow-lg"
            imgClassName="object-contain"
            image={data.splash.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do students practicing spear forms"
          />
        </div>
      </section>

      <PhotoGallery title="Yuan Yen Do Summer Camp" caption="Take a sneak peak on whats in store during our summer camp" gallery={data.gallery} />
      <ContactUs />
    </>
  );
}
